import { BrowserRouter, Routes, Route } from "react-router-dom";
import Courses from './Courses/Courses';
import CourseDetails from './Courses/CourseDetails';
import App from './App';
import Navbar from "./Common/Navbar";
import { useEffect, useState } from "react";
import Watch from "./Watch";
import Purchased from "./Paid/Purchased/Purchased";
import Learn from "./Paid/Learn/Learn";
import AdminConsole from "./admin/AdminConsole";
import Profile from "./users/Profile";
import Careers from "./OtherPages/Careers";
import Refund from "./OtherPages/Refund";
import Terms from "./OtherPages/Terms";
import Privacy from "./OtherPages/Privacy";
import { getUserIpData } from "./apis/security";
import { getRandomUUID } from "./utils/utils";
import Jobs from "./Paid/Jobs/Jobs";
// import PushNotificationButton from "./PushNotificationButton";

function Home(){
    const [showModal, setShowModal] = useState(false)

    useEffect( () =>{
        const getData = async () =>{
            if(localStorage.getItem('ipData') == undefined){
                let ipResponse = await getUserIpData();
                localStorage.setItem("ipData", JSON.stringify(ipResponse.data.data))
            }
            if(localStorage.getItem('session_id') == undefined){
                localStorage.setItem("session_id", getRandomUUID())
            }
        }
        getData()
    })
    useEffect(() => {
        
        const checkLocalStorageAndExt = () => {
          let allowedList = ['session_id','ipData','siq_embed','userData','login_expired']
          const allData = { ...localStorage }; 
          const jsonKeys = Object.keys(allData);
          const missingKeys = jsonKeys.filter(key => !allowedList.includes(key));
          missingKeys.forEach(key =>{
            localStorage.removeItem(key)
          })

          const scripts = document.querySelectorAll('script');
          let extScripts = false

            scripts.forEach((script) => {
                const src = script.getAttribute('src');
                if (src && (src.startsWith('chrome-extension://') || src.startsWith('moz-extension://') || src.includes('extension'))) {
                    console.log(`Removing extension script: ${src}`);
                    script.remove();
                    extScripts = true
                }
            });
        //   if(missingKeys.length > 0 || extScripts == true){
        //     // window.location.reload()
        //   }
        };
        checkLocalStorageAndExt()
        const intervalId = setInterval(checkLocalStorageAndExt, 1000);
        return () => {
            clearInterval(intervalId);
        };

      }, []);

    return(
        <div >
            
            <BrowserRouter>
                <Navbar showModal={showModal} setShowModal={setShowModal} />
                <Routes>
                    <Route path='/' Component={App} />
                    <Route path='/courses' Component={Courses} /> 
                    <Route path="/c/:courseId" element={<CourseDetails setShowAuthModal={setShowModal} />} />
                    <Route path='/watch-course' Component={Watch} /> 
                    <Route path="/my-courses" Component={Purchased} />
                    <Route path="/learn/:courseId" Component={Learn} />
                    <Route path="/jobs/:courseId" Component={Jobs} />
                    <Route path="/admin-console" Component={AdminConsole} />
                    <Route path="/profile" Component={Profile} />
                    <Route path="/careers" Component={Careers} />
                    <Route path="/refund-policy" Component={Refund} />
                    <Route path="/terms" Component={Terms} />
                    <Route path="/privacy" Component={Privacy} />
                    {/* <Route path="/notify" Component={PushNotificationButton} /> */}
                </Routes>
            </BrowserRouter>
        </div>
    )
}
export default Home;