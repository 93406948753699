import { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import Course from "./Course";
import { apiGetPurchasedCourses } from "../../apis/purchases";
import { vars } from "../../utils/constants";
import { generateRandomKey } from "../../utils/crypto";
import { parseApiResponse } from '../../utils/api';
// import '../../header.css'
import Offers from '../../Common/Offers'

const Purchased = () =>{

    let [coursesList, setCoursesList] = useState([])

    useEffect(() =>{
        const getData = async() => {
            const aesKey = generateRandomKey()
            let response = await apiGetPurchasedCourses(aesKey)
            response = parseApiResponse({data: response.data, aesKey: aesKey})
            if(response.data.result == vars.SUCCESS){
                setCoursesList([...response.data.data.courses])
            }else{
                setCoursesList([])
            }
        }
        getData();
    },[])

    return(
        <div >
            <main id="content">
                {/* <Offers /> */}
                <div className="bg-light pb-6">
                    <div className="container position-relative content-space-t-3 content-space-t-md-4 content-space-t-lg-4 pb-6">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-12 text-center mb-4">
                                <div className="mb-7 ">
                                    <h2 className="display-4 mb-3 h1">Your Courses</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 mb-md-0 pb-3">
                            {
                                coursesList.map( c => (
                                    <Course key={c.l_course_id} data={c} />
                                ))
                            }
                            {
                                coursesList.length == 0 && <div className="alter alter-danger">NO courses purchased</div>
                            }
                        </div>
                        
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Purchased;