import Footer from "../Common/Footer"

function Careers(){


    return(
        <div >
            <main id="content">
                <div className="bg-light">
                    <div className="container ">
                        <div className="row justify-content-lg-between align-items-lg-center pt-5 ">
                            <div className="col-lg-12 mt-5 pt-5 text-center">
                                <h2 className="mt-5">Careers</h2>
                                <div className="mt-5 mb-5">
                                    No openings now, will update here.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Careers