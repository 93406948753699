import { Link } from "react-router-dom";

function Course({data}){

    return(
        <div className="col-sm-6 col-lg-4 mb-4">
            <div className="card card-sm h-100 shadow-none  card-transition">
                <div className="p-2">
                    <Link to={"/c/"+ data.l_course_seo_url}>
                        <img className="card-img lazy" src={data.l_course_img_url} alt={data.l_course_name} />
                    </Link>
                </div>
                <div className="card-body">
                    <h3 className="card-title">{data.l_course_name}</h3>
                    <p className="card-text">{data.l_course_tags}</p>
                    <ul className="list-pointer mb-0">
                        {
                            data.points.split(',').map( (item, i) => (
                                <li className="list-pointer-item" key={i}>{item}</li>
                            ))
                        }
                    </ul>
                    <div className="d-grid mt-3">
                        <Link className="btn btn-soft-warning" to={"/c/"+data.l_course_seo_url} >Course details</Link>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Course;