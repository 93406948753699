import spacetime from 'spacetime';

export const getDateTimeInUserTimezone = (timestampinUtc) => {
	let userTimeZone = spacetime.now().tz;
	let s = spacetime(timestampinUtc, 'utc').goto(userTimeZone);
	return s.format('{year}-{iso-month}-{date-pad}  {time}');
};

export const getUTCtimeStamp = (dateTime) =>{
	let userTimeZone = spacetime.now().tz;
	var d = spacetime(dateTime, userTimeZone).goto('utc');
	return d.epoch;
}

export const getCurrentUTCtime = () => {
	return spacetime.now('utc').epoch;
};

export const getTimeDiff = (dateTime) =>{
	let d = spacetime.now('utc');
	return d.diff(dateTime, 'day');
}