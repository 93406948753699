
import Footer from '../Common/Footer';
import Navbar from '../Common/Navbar';
import {useState} from 'react'
import { apiUpdatePassword } from '../apis/users';
import { vars } from '../utils/constants';
import { generateRandomKey } from '../utils/crypto';
import { parseApiResponse } from '../utils/api';
import Offers from '../Common/Offers'
function Profile() {

    const [uiData, setUiData] = useState({successMsg:'', failMsg: '', pword: '', isApiLoading: false, pwordErrorMsg: ''})

    const updatePword = async() =>{
        try{
            if(uiData.isApiLoading == false){
                if(uiData.pword.length > 7){
                    setUiData({...uiData, isApiLoading: true, pwordErrorMsg: ''})
                    const aesKey = generateRandomKey()
                    let response = await apiUpdatePassword(uiData.pword ,aesKey);
                    response = parseApiResponse({data: response.data, aesKey: aesKey})
                    if(response.data.result == vars.SUCCESS){
                        setUiData({...uiData, successMsg: response.data.message, failMsg: '', isApiLoading: false, pword: ''})
                    }else{
                        setUiData({...uiData, successMsg: '', failMsg: response.data.message, isApiLoading: false})
                    }
                }else{
                    setUiData({...uiData, pwordErrorMsg: 'Min 8 chars'})
                }
            }
        }catch(ex){
            setUiData({...uiData, successMsg: '', failMsg: ex.message, isApiLoading: false})
        }
    }

    return (
    <div >
        <main id="content">
            <Offers />
            <div className="bg-light pb-6">
                <div className="container position-relative content-space-t-3 content-space-t-md-4 content-space-t-lg-4 pb-6">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-12 text-center mb-4">
                            <div className="mb-7 ">
                                <h2 className="display-4 mb-3 h1">Update Profile</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-5 mb-md-0 pb-3">
                        <div className='col-lg-4'></div>
                        <div className='col-lg-4'>
                            <div className='card shadow border-0'>
                                <div className='card-body'>
                                    <div className='mt-3'>
                                        <label>Password</label>
                                        <input type="password" value={uiData.pword} onChange={e=>setUiData({...uiData, pword: e.target.value})} className='form-control' placeholder='New Password' />
                                        <div className='text-danger'>
                                            {uiData.pwordErrorMsg}
                                        </div>
                                    </div>
                                    <div className='mt-3'>
                                        <button className='btn btn-warning' onClick={ e => updatePword() } >Update Password</button>
                                    </div>
                                    <div className='mt-3'>
                                        {
                                            uiData.failMsg.length > 0 && <div className="alert alert-danger" role="alert">{uiData.failMsg}</div>
                                        }
                                        {
                                            uiData.successMsg.length > 0 && <div className="alert alert-success" role="alert">{uiData.successMsg}</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4'></div>
                    </div>
                    
                </div>
            </div>
        <Footer />
        </main>
    </div>
    );
    }

export default Profile;
