import { getOpenAxios } from '../utils/axios';

export const apiValidateCoupon = async (data) => {
	return getOpenAxios().post('/coupon/validate-coupon',data);
};







