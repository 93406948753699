export const TEXTBOX_TYPES = ['text', 'email', 'number', 'date','datetime-local'];
export const TEXTAREA_TYPES = ['textarea', 'editor'];
export const DROPDOWN_TYPES = ['select', 'multi-select','multiple'];
export const TIMINGS = [
	'12 AM', '01 AM','02 AM','03 AM','04 AM','05 AM','06 AM','07 AM','08 AM','09 AM','10 AM',
	'11 AM', '12 PM','01 PM','02 PM','03 PM','04 PM','05 PM','06 PM','07 PM','08 PM','09 PM','10 PM','11 PM'
];
export const RADIO = ['radio'];
export const CHECKBOX  = ['checkbox'];
export const CHECKEDLIST  = ['checked_list'];
export const USER_PROFILES = ['profiles_list'];
export const FILE = ['file'];
export const RATING = ['rating'];

export const vars = {
	API_URLS:{
		LOCAL: 'http://localhost:8080',
		PROD: 'https://api.softwareschool.co'
	},
	adminsList:[
		2
	],
	courseTopicTypes:{
		VIDEO: 'video',
		ASSIGNMENT: 'assignment',
		CATEGORIES:{
			CLASS:'class',
			LIVE:'live'
		}
	},
	cookieNames:{
		AWS_CLOUDFRONT_SECRET:'aws-cf-secret'
	},
	couponData:{
		PRICE:'price',
		PERCENTAGE:'percentage'
	},
	SUCCESS: 'SUCCESS',
	FAILED: 'FAILED',
	TOKEN_MISSING: 'TOKEN_MISSING',
	TOKEN_MAL_FUNCTION: 'TOKEN_MAL_FUNCTION',
	OK: 'OK',
	PARAMS_MISSING: 'PARAMS_MISSING',
	UNABLE_TO_PROCESS_YOUR_REQUEST: 'UNABLE_TO_PROCESS_YOUR_REQUEST',
	AWS_ASSETS_URL: 'https://d3nxjq0rqpx323.cloudfront.net/',
	pingTags:{
		MEETING:'MEETING',
		TASK:'TASK',
		BLANK:'BLANK'
	},
	messageTypes: {
		SIMPLE_PING: 'SIMPLE_PING',
		MEETING_PING:'MEETING_PING',
		TASK_PING:'TASK_PING',
		ACTION_FORM_PING:'ACTION_FORM_PING',
		FILL_FORM_PING:'FILL_FORM_PING',
		INLINE_FORM_PING:'INLINE_FORM_PING',
		BLANK_PING:'BLANK_PING',
		FORM_RESPONSE_PING:'FORM_RESPONSE_PING',
		FILES_PING:'FILES_PING'
	},
	formTypes:{
		INLINE: 'INLINE_FORM_PING',
		FILL:'FILL_FORM_PING',
		ACTION:'ACTION_FORM_PING'
	},
	formTitles:{
		MEETING: 'meeting',
		TASK: 'task',
		BLANK_FORM:'form'
	},
	formValueTypes:{
		SINGLE_ITEM: 'SINGLE_ITEM',
		MULTIPLE_ITEM: 'MULTIPLE_ITEM'
	},
	formSizes:{
		fullWidth:['textarea', 'editor','checked_list', 'file']
	},
	PROFILE_LARGE_PIC_PREFIX: '150_',
	PROFILE_SMALL_PIC_PREFIX: '60_',
	EDITOR_TEXT_BOX:{
		options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
		inline: {
			inDropdown: false,
			options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
		},
		blockType: {
			inDropdown: true,
		},
		list: {
			inDropdown: false,
			options: ['unordered', 'ordered'],
		},
		textAlign: {
			inDropdown: false,
		},

		link: {
			inDropdown: false,
		},
		// image: {
		// 	className: 'img-fluid',
		// 	urlEnabled: true,
		// 	uploadEnabled: true,
		// 	uploadCallback: uploadImageCallBack,
		// 	inputAccept: 'image/gif,image/jpeg,image/jpg,image/png',
		// 	alt: { present: false, mandatory: false },
		// 	defaultSize: {
		// 		height: '100%',
		// 		width: '100%',
		// 	},
		// 	alignmentEnabled: false,
		// },
	},
	FORM_FIELDS: {
		TEXTBOX_TYPES: TEXTBOX_TYPES,
		TEXTAREA_TYPES: TEXTAREA_TYPES,
		DROPDOWN_TYPES: DROPDOWN_TYPES,
		TIMINGS:TIMINGS,
		RADIO:RADIO,
		CHECKBOX:CHECKBOX,
		CHECKEDLIST:CHECKEDLIST,
		USER_PROFILES:USER_PROFILES,
		FILE:FILE,
		RATING: RATING
	},
	FORM_ELEMENT_META_DATA:{
		SHOW_ADD_VALUE_OPTION: [...DROPDOWN_TYPES, ...RADIO, ...CHECKBOX, ...CHECKEDLIST],
		SINGLE_VALUE_ELEMENTS: [...TEXTBOX_TYPES, ...TEXTAREA_TYPES, ...DROPDOWN_TYPES, ...RADIO, ...RATING ],
		MULTI_VALUE_ELEMENTS: [...CHECKBOX, ...CHECKEDLIST, ...USER_PROFILES, ...FILE ],
		NOT_ALLOWED_FOR_USER_INPUT:[...USER_PROFILES],
	},
	ERB:{
		value: '0',
		ele_value:''
	},
	MAX_DATA_LENGHTS:{
		NAME: 20,
		EMAIL: 25
	},
	BUTTON:{
		LOGIN: 'login',
		START_CONVO:'startConvo',
		SEND_PING:'sendPing',
		SUBMIT_PING:'submitPing',
		SUBMIT_FORM_DATA:'submitFormData',
		UPDATE_CONVO_DETAILS:'updateConvo',
		ADD_PARTICIPANT:'addParticipant',
		ADD_NOTE:'addNote',
		UPDATE_NOTE:'updateNote',
		UPDATE_PROFILE:'updateProfile',
		FORGOT_PASSWORD:'forgotPassword',
		SIGNUP:'signup',
		TEMPLATE_SAVE:'templateSave',
		TEMPLATE_UPDATE:'templateUpdate',
		JOIN_WAITLIST: 'waitlist',
		FILE_UPLOAD:'fileUpload',
		ADD_TO_CLIPBOARD:'addToClipboard'
		
	},
	BLANK_TEMPLATE_ID:'16b2f150-a481-11ec-8086-0a0939d5bf7e',
	MQTT_MSG_TYPES:{
		NEW_PING:'NEW_PING'
	},
	PING_SOURCE:{
		PING_PORTAL: 'PING_PORTAL',
		API: 'API'
	},
	PING_STATUS:{
		COMPLETED: 'COMPLETED',
		NOT_COMPETED:'NOT_COMPETED',
		SHOW_IN_PORTAL:'SHOW_IN_PORTAL',
		DONT_SHOW_IN_PORTAL:'DONT_SHOW_IN_PORTAL'
	},
	ERB_DAYS:{
		TODAY: 'Today',
		TOMORROW:'Tomorrow',
		DAYS:'Days'
	},
	FORM_ELEMENT_PARSER_TYPES:{
		DATE_TIME:'datetime-local'
	},
	FILES_UPLOAD:{
		EXCLUDES:["exe"],
		MAX_SIZE: 6291456,
		ERRORS: {
			EXT_NOT_ALLOWED:'File type not allowed',
			SIZE_EXCEDED:'Max file size is 6 MB'
		},
		ONE_KB: 1024,
		ONE_MB: 1048576,
		ONE_GB: 1073741824,
		ASSIGNMENT_INCLUDES:["zip","rar"]
		
	},
	CRYPTO:{
		AES_KEY:'aesKey',
		RSA_KEY:'rsaKey'
	}
};


