import { useEffect, useRef } from "react";

function TogleFullscreen({handleChange}){
    
    const intervalRef= useRef(null);
    useEffect(() => {
        intervalRef.current = setInterval(async () => {
            handleChange()
        }, 600000);
        
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

}

export default TogleFullscreen