import Footer from "../Common/Footer"

function Refund(){


    return(
        <div >
            <main id="content">
                <div className="bg-light">
                    <div className="container ">
                        <div className="row justify-content-lg-between align-items-lg-center pt-5 ">
                            <div className="col-lg-12 mt-5 pt-5 ">
                                <h2 className="mt-5 text-center">Refund Policy</h2>
                                <div className="mt-5 mb-5">
                                <ul>
                                    <li>Refunds will be provided only within 7 days of the initial purchase date, subject to the discretion of SoftwareSchool.</li>
                                    <li>Refunds will not be issued if you have accessed more than 15% of the course content.</li>
                                    <li>To request a refund, please contact contact@softwareschool.co with your purchase details.</li>
                                </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Refund