function Footer(){

    return(
        <footer className="shadow">
            <div className="container pb-1 pb-lg-7">
                <div className="row content-space-t-2">
                <div className="col-12 col-lg-3 mb-7 mb-lg-0">
                    <div className="mb-5">
                    <a className="navbar-brand" href="./index.html" aria-label="Space">
                        <img className="navbar-brand-logo lazy"  src="https://d2b98ifobtd07j.cloudfront.net/l12.png" alt="Image Description" />
                    </a>
                    </div>

                    learn from industry experts and become job-ready in 6 months

                </div>

                <div className="col-6 col-sm mb-7 mb-sm-0">
                    <h5 className="mb-3">Company</h5>

                    <ul className="list-unstyled list-py-1 mb-0">
                    {/* <li><a className="link-sm link-secondary" href="about">About</a></li> */}
                    <li><a className="link-sm link-secondary" href="careers">Careers <span className="badge bg-warning text-dark rounded-pill ms-1">We're hiring</span></a></li>
                    <li><a className="link-sm link-secondary" target="_blank" href="https://edstaack.medium.com">Blog <i className="bi-box-arrow-up-right small ms-1"></i></a></li>
                    </ul>
                </div>

                <div className="col-6 col-sm mb-7 mb-sm-0">
                    <h5 className="mb-3">Resources</h5>

                    <ul className="list-unstyled list-py-1 mb-0">
                    <li><a className="link-sm link-secondary" href="refund-policy">Refund policy</a></li>
                    <li><a className="link-sm link-secondary" href="privacy">Privacy</a></li>
                    <li><a className="link-sm link-secondary" href="terms">Terms</a></li>
                    </ul>
                </div>

                <div className="col-6 col-sm">
                    <h5 className="mb-3">Socail Connect</h5>

                    <ul className="list-unstyled list-py-1 mb-0">
                    <li><a className="link-sm link-secondary" target="_blank" href="https://www.youtube.com/channel/UCbBI_p0ZtBQeDwRdOzPjARw"><i className="bi-youtube small ms-1"></i> &nbsp;YouTube</a></li>
                    <li><a className="link-sm link-secondary" target="_blank" href="https://discord.com/channels/927249456519336026/927249495786397777"><i className="bi-discord small ms-1"></i> &nbsp;Discord</a></li>
                    <li><a className="link-sm link-secondary" target="_blank" href="https://linkedin.com/company/softwareschool"><i className="bi-linkedin small ms-1"></i> &nbsp;LinkedIn</a></li>
                    <li><a className="link-sm link-secondary" target="_blank" href="https://www.instagram.com/softwareschool.co"><i className="bi-instagram small ms-1"></i> &nbsp;Instagram</a></li>
                    <li><a className="link-sm link-secondary" target="_blank" href="https://www.facebook.com/edstaack"><i className="bi-facebook small ms-1"></i> &nbsp;Facebook</a></li>

                    </ul>
                </div>

                <div className="col-6 col-sm">
                    <h5 className="mb-3">Contact</h5>

                    <ul className="list-unstyled list-py-1 mb-5">
                    <li><i className="bi bi-geo-alt-fill me-1"></i> HIG, Chitrapuri Colony, Manikonda, Hyderabad, Telangana</li>
                    <li><a className="link-sm link-secondary" href="mailto:contact@softwareschool.co"><i className="bi-envelope-fill me-1"></i> contact@softwareschool.co</a></li>
                    <li><a className="link-sm link-secondary" href="tel:+919032029072"><i className="bi-telephone-inbound-fill me-1"></i> +91 9032029072</a></li>
                    <li><a className="link-sm link-secondary" target="_blank"  href="https://web.whatsapp.com/send?phone=919032029072&text=I+want+to+land+on+software+job.+Tell+me+how%3F&app_absent=0"><i className="bi-whatsapp me-1"></i>WhatsApp us</a></li>
                    </ul>
                </div>
                </div>

                <div className="w-md-85 text-lg-center mx-lg-auto pt-5 mt-3">
                <p className="text-muted small">© Softwareschool.co 2024. All rights reserved.</p>
                <p className="text-muted small">When you visit or interact with our sites, services or tools, we or our authorised service providers may use cookies for storing information to help provide you with a better, faster and safer experience and for marketing purposes.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;