import { vars } from "./constants";

export const  getFileExtension = (filename) =>{
    const extension = filename.split('.').pop();
    return extension;
}

export const  getFileNameWithoutExtention = (filename) =>{
    var lastDotPosition = filename.lastIndexOf(".");
    if (lastDotPosition === -1) return filename;
    else return filename.substr(0, lastDotPosition);
}

export const getFileSizeInWords = (size) =>{
    if(size < vars.FILES_UPLOAD.ONE_KB){
        return size.toFixed(2) + " Bytes";
    }else if(size < vars.FILES_UPLOAD.ONE_MB){
        return (size / vars.FILES_UPLOAD.ONE_KB).toFixed(2) + " KB";
    }else if(size < vars.FILES_UPLOAD.ONE_GB){
        return (size / vars.FILES_UPLOAD.ONE_MB).toFixed(2) + " MB";
    }else{
        return (size / vars.FILES_UPLOAD.ONE_GB).toFixed(2) + " GB";
    }
}




