import JSEncrypt from "jsencrypt";
var CryptoJS = require("crypto-js");


export const charBank ="0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz*&-%/!?*+=()";
export const keyLength = 75;
export const rsaPublicKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAn+EO2el0/kbozv6ggo3J
CQSW5bAsTOUTTukQZo0pC9nyJSO5UAFJSaR8hjAH7/qhdAkOd8DZ2RT0ir6f1Ffs
9OYyBFl/U2y/BCYYnvAmzl4p9HbEst7/OvaQrmQ6txh0jeXh6kzD2W3qXKnSpKE3
f2mO+3P6Jd/O0flYHWTc2IafB4V8/T6Emtvz9gNqzEDeMY5gmJOkF5fDXMPYLDKT
8F6wOmwZNTqumyK6ubrqBmRcfs7YQtIfEso6HXNOZsIf4Xt/euuMV2D6lY2D38j0
UyQix+XGKokvENr+uZZyQjAPfUFUNX+qIb8DYMyvJBUxPKsvuzD+Q7TFyEZN+Ohb
GQIDAQAB
-----END PUBLIC KEY-----`;
export const generateRandomKey = () =>{
    var randomstring = '';
    for (var i=0; i < keyLength; i++) {
        var rnum = Math.floor(Math.random() * charBank.length);
        randomstring += charBank.substring(rnum,rnum+1);
    }
    return randomstring;
}

export const encryptRsa = (data) =>{
    const crypto = new JSEncrypt();
    crypto.setPublicKey(rsaPublicKey);
    return crypto.encrypt(data);
}

export const encryptAES = (data, key) =>{
    return CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
}

export const encryptAESData = (data, key) =>{
    return CryptoJS.AES.encrypt(data, key).toString();
}

export const decryptAesData = (data, key) =>{
	var bytes  = CryptoJS.AES.decrypt(data, key);
	return bytes.toString(CryptoJS.enc.Utf8);
}
