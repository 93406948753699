import { useEffect, useRef } from "react";
import { apiValidateLoginKey } from "../apis/security";
import { generateRandomKey } from "../utils/crypto";
import { vars } from "../utils/constants";
import { parseApiResponse } from "../utils/api";

function TokenValidator(){

    const intervalRef= useRef(null);

    useEffect(() => {
        intervalRef.current = setInterval(async () => {
            const aesKey = generateRandomKey()
            let response = await apiValidateLoginKey(aesKey)
            response = parseApiResponse({data: response.data, aesKey: aesKey})
            if(response.data.result == vars.FAILED){
                localStorage.clear()
                localStorage.setItem("login_expired", true)
                window.location = "/"
            }
        
        }, 30000);
        
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

    return <></>
}

export default TokenValidator

