function Section4(){

    return(
        <div className="bg-soft-light">
            <div className="overflow-hidden">
                <div className="container content-space-t-lg-3 content-space-b-lg-2">

                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h2>Industry experts as your tutors</h2>
                                <p>All our tutors are from the top IT companies and they are very good at building real-time applications at massive scale. </p>
                            </div>
                        </div>

                        <div className="col-lg-6 order-lg-1">
                            <div className="position-relative mx-auto" data-aos="fade-up">
                                <div className="w-75 mx-auto">
                                    <img className="img-fluid rounded-top-2 lazy"  src="https://d2b98ifobtd07j.cloudfront.net/h_experts.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="container content-space-t-lg-3 content-space-b-lg-2">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-5 order-lg-1 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h2>Beginner to job-ready </h2>
                                <p>We will teach you from basics to advanced level. You don't have to learn anything prior to taking courses from us. You will become job-ready by end of the course. </p>
                            </div>
                        </div>

                        <div className="col-lg-6 order-lg-2">
                            <div className="position-relative mx-auto" data-aos="fade-up">
                                <div className="w-75 mx-auto">
                                    <img className="img-fluid rounded-top-2 lazy"  src="https://d2b98ifobtd07j.cloudfront.net/h_1.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="container content-space-t-lg-3 content-space-b-lg-2">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h2>Real-time concepts, no theory</h2>
                                <p>Our curriculam includes 10% theiry and 90% real-time concepts. You will be able to build real-time apps by end of the course.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 order-lg-1">
                            <div className="position-relative mx-auto" data-aos="fade-up">
                                <div className="w-75 mx-auto">
                                    <img className="img-fluid rounded-top-2 lazy"  src="https://d2b98ifobtd07j.cloudfront.net/h_3.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="container content-space-t-lg-3 content-space-b-lg-2">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-5 order-lg-1 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h2>Real-time projects & Mock interviews</h2>
                                <p>You will learn by building real-time applications diring the training and We prepare you for the interviews and will take 2 mock interviews</p>
                            </div>
                        </div>

                        <div className="col-lg-6 order-lg-2">
                            <div className="position-relative mx-auto" data-aos="fade-up">
                                <div className="w-75 mx-auto">
                                    <img className="img-fluid rounded-top-2 lazy"  src="https://d2b98ifobtd07j.cloudfront.net/h_6.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="overflow-hidden">
                <div className="container content-space-t-lg-3 content-space-b-lg-2">
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-5 order-lg-2 mb-9 mb-lg-0">
                            <div className="mb-5">
                                <h2>1 week money back gurantee</h2>
                                <p>If you don't like our teaching, we will refund your money without asking any questions.</p>
                            </div>
                        </div>

                        <div className="col-lg-6 order-lg-1">
                            <div className="position-relative mx-auto" data-aos="fade-up">
                                <div className="w-75 mx-auto">
                                    <img className="img-fluid rounded-top-2 lazy"  src="https://d2b98ifobtd07j.cloudfront.net/h_5.png" alt="Image Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4;