function StepsForJob(){


    return(
        <div className="bg-soft-success">
            <div className="container content-space-2 content-space-lg-2">

                <div className="row">
                    <div className="col-12 text-center mb-4 mb-lg-5 pb-5">
                        <h2 className="h1 lh-sm"><span className=" text-highlight-warning text-primary">6 steps </span> for dream job</h2>
                    </div>


                    <div className="col-sm-6 col-lg-4 mb-6">
                        <div className="card card-transition h-100 shadow-none overflow-hidden">

                            <div className="row align-items-center">
                                <div className="col-12 col-md-12">
                                    <div className="card-pinned mb-3">
                                        <span className="card-pinned-top-start">
                                            <span className="badge bg-soft-primary rounded-pill text-cap text-primary">Step 1</span>
                                        </span>
                                    </div>
                                    <div className="card-body mt-3">
                                        <h2 className="mb-3">Choose in-demand technology</h2>
                                        <p className="card-text">You need to learn career paths not just basic courses. You need to learn what industry wants. Choose most in-demand technology to land job quickly. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-6">
                        <div className="card card-transition h-100 shadow-none overflow-hidden">

                            <div className="row align-items-center">
                                <div className="col-12 col-md-12">
                                    <div className="card-pinned mb-3">
                                        <span className="card-pinned-top-start">
                                            <span className="badge bg-soft-primary rounded-pill text-cap text-primary">Step 2</span>
                                        </span>
                                    </div>
                                    <div className="card-body mt-3">
                                        <h2 className="mb-3">Learn real-time skills not theory</h2>
                                        <p className="card-text">Learning theory won't help you in cracking interviews. Companies want someone who can code not theory specialists. Learn real-time concepts to crack interviews easily. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-6">
                        <div className="card card-transition h-100 shadow-none overflow-hidden">

                            <div className="row align-items-center">
                                <div className="col-12 col-md-12">
                                    <div className="card-pinned mb-3">
                                        <span className="card-pinned-top-start">
                                            <span className="badge bg-soft-primary rounded-pill text-cap text-primary">Step 3</span>
                                        </span>
                                    </div>
                                    <div className="card-body mt-3">
                                        <h2 className="mb-3">Practice by building demo apps</h2>
                                        <p className="card-text">Get hands-on experince by building one or two demo projects. You can build blog or ecommerce webiste with minimal features, You can remember most of the things during interview.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-6">
                        <div className="card card-transition h-100 shadow-none overflow-hidden">

                            <div className="row align-items-center">
                                <div className="col-12 col-md-12">
                                    <div className="card-pinned mb-3">
                                        <span className="card-pinned-top-start">
                                            <span className="badge bg-soft-primary rounded-pill text-cap text-primary">Step 4</span>
                                        </span>
                                    </div>
                                    <div className="card-body mt-3">
                                        <h2 className="mb-3">GitHub profile to showcase coding skills</h2>
                                        <p className="card-text">Github is a platform for sharing & collbarating. Upload your code to github and share your repositories links along with your resume, so that interviwers will get good understanding about your coding skills. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-6">
                        <div className="card card-transition h-100 shadow-none overflow-hidden">

                            <div className="row align-items-center">
                                <div className="col-12 col-md-12">
                                    <div className="card-pinned mb-3">
                                        <span className="card-pinned-top-start">
                                            <span className="badge bg-soft-primary rounded-pill text-cap text-primary">Step 5</span>
                                        </span>
                                    </div>
                                    <div className="card-body mt-3">
                                        <h2 className="mb-3">Resume, LinkedIn & Naukri profiles</h2>
                                        <p className="card-text">Build best resume with github profile links, projects links and upload it on naukri. LinkedIn is the best platform for professionals. Create your profile and get in touch with recruiters who are actively hiring. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-6 col-lg-4 mb-6">
                        <div className="card card-transition h-100 shadow-none overflow-hidden">

                            <div className="row align-items-center">
                                <div className="col-12 col-md-12">
                                    <div className="card-pinned mb-3">
                                        <span className="card-pinned-top-start">
                                            <span className="badge bg-soft-primary rounded-pill text-cap text-primary">Step 6</span>
                                        </span>
                                    </div>
                                    <div className="card-body mt-3">
                                        <h2 className="mb-3">Interview prep & mock interviews </h2>
                                        <p className="card-text">Interview preparation and mock interviews are the most important things before attending any interview. Go through the frequently asked asked questions and practive two or more mock interviews. </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default StepsForJob;
