import { useEffect, useState } from "react";
import Footer from "../../Common/Footer";
import { apiGetPurchasedCourses } from "../../apis/purchases";
import { vars } from "../../utils/constants";
import { generateRandomKey } from "../../utils/crypto";
import { parseApiResponse } from '../../utils/api';
// import '../../header.css'
import Offers from '../../Common/Offers'
import Job from "./Job";
import { apiGetJobNotifications } from "../../apis/jobs";
import { useParams } from "react-router-dom";

const Jobs = () =>{

    let [jobsList, setJobsList] = useState([])
    const { courseId } = useParams();

    useEffect(() =>{
        const getData = async() => {
            const aesKey = generateRandomKey()
            let response = await apiGetJobNotifications(aesKey, courseId)
            response = parseApiResponse({data: response.data, aesKey: aesKey})
            if(response.data.result == vars.SUCCESS){
                setJobsList([...response.data.data.jobs])
            }else{
                setJobsList([])
            }
        }
        getData();
    },[])

    return(
        <div >
            <main id="content">
                {/* <Offers /> */}
                <div className="bg-light pb-6">
                    <div className="container position-relative content-space-t-3 content-space-t-md-4 content-space-t-lg-4 pb-6">
                        <div className="row justify-content-lg-between align-items-lg-center">
                            <div className="col-lg-12 text-center mb-4">
                                <div className="mb-7 ">
                                    <h2 className="display-4 mb-3 h1">Job Notifications</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5 mb-md-0 pb-3">
                           {
                            jobsList.map( data =>(
                                <Job data={data} />
                            ))
                           }
                            {
                                jobsList.length == 0 && <div className="alter alter-danger">No job notifications available at this time.</div>
                            }
                        </div>
                        
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Jobs;