import axios from 'axios';
import { vars } from './constants';
import { encryptAES, encryptRsa } from './crypto';
import { getLoggedInUserId, getUserJwtToken } from './utils';

const API_URL = vars.API_URLS.PROD;
const openAxios = axios.create({
	baseURL: API_URL,
});

const tokenAxios = axios.create({
	baseURL: API_URL,
});


export const getJwtAxios = () => {
	tokenAxios.interceptors.request.use( req =>{
		// console.log(req)
		if (req.data instanceof FormData) {
			// console.log(2)
		} else if (typeof req.data === 'object' && req.data !== null) {
			// console.log(1)
			if(req.data.hasOwnProperty(vars.CRYPTO.AES_KEY)){
				const aesKey = req.data[vars.CRYPTO.AES_KEY];
				const rsaKey = encryptRsa(aesKey);
				let data = {...req.data};
				delete data[vars.CRYPTO.AES_KEY];
				const encData = encryptAES(data, aesKey);
				req.data = { data: encData, rsaKey:rsaKey, token: getUserJwtToken(), userId: getLoggedInUserId()   };
			}else{
				req.data = { ...req.data, token: getUserJwtToken(), userId: getLoggedInUserId()   };
			}
		}
		return req;
	})

	// tokenAxios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
	tokenAxios.interceptors.response.use(
		(response) => {
			if (response.headers.hasOwnProperty('token')) {
				let data = JSON.parse(localStorage.getItem('userData'))
				data = {...data, token: response.headers.token}
				localStorage.setItem('userData', JSON.stringify(data));
			}
			return response;
		},
		(error) => {
			return Promise.reject(error);
		}
	);

	return tokenAxios;
};

export const getOpenAxios = () => {
	// openAxios.interceptors.request.use( req =>{
	// 	if(req.data.hasOwnProperty(vars.CRYPTO.AES_KEY)){
	// 		const aesKey = req.data[vars.CRYPTO.AES_KEY];
	// 		const rsaKey = encryptRsa(aesKey);
	// 		let data = {...req.data};
	// 		delete data[vars.CRYPTO.AES_KEY];
	// 		const encData = encryptAES(data, aesKey);
	// 		req.data = { data: encData, rsaKey:rsaKey  };
	// 	}
	// 	return req;
	// })
	return openAxios;
};
