import { useState } from "react";
import { apiGetUsers } from "../apis/admin";
import { vars } from "../utils/constants";

function AdminConsole(){

    const [links, setLinks] = useState([
        {id: 1, title:'Learners', is_active_tab: false}, {id: 2, title:'Add Learner', is_active_tab: false}, 
        {id: 3, title:'Orders', is_active_tab: false}, {id: 4, title:'Add Order', is_active_tab: false}, 
        {id: 5, title:'Purchases', is_active_tab: false}, {id: 6, title:'Add Purchase', is_active_tab: false},  
    ])

    const [activeLink, setActiveLink] = useState({id: 0, title:''})

    const getAdminLinkClasses = (link) =>{
        return link.is_active_tab ? "list-group-item pointer text-primary border-primary" : "list-group-item pointer"
    }

    const [users,setUsers] = useState([])

    const getUsers = async() =>{
        try{
            let response = await apiGetUsers()
            if(response.data.result == vars.SUCCESS){
                setUsers([...response.data.data.users])
            }else{
                setUsers([])
            }
        }catch(ex){
            setUsers([])
        }
    }

    const handleAdminLinkClick = (link) =>{
        let data = links.map( l => {
            if(l.id == link.id){
                l.is_active_tab = true
            }else{
                l.is_active_tab = false
            }
            return l;
        })
        setActiveLink({...link})
        setLinks([...data])
        if(link.id == 1){
            getUsers()
        }
    }

    return(
        <div >
            <main id="content">
                <div className="bg-light pb-6">
                    <div className="container position-relative content-space-t-3 content-space-t-md-4 content-space-t-lg-4 pb-6">
                        <div className="row ">
                            {/*  */}
                            <div className="col-sm-6 col-lg-3 mb-4">
                                <div className="card card-sm h-100 shadow-none  card-transition ss-scroll">
                                    <ul className="list-group list-group-flush">
                                        {
                                            links.map( (link, i) => (
                                                <li className={getAdminLinkClasses(link)} onClick={e=>handleAdminLinkClick(link)}  key={link.id}>
                                                    <div>{link.title}</div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            {/*  */}
                            {
                                activeLink.id == 1 && users.length > 0 &&
                                <div className="col-lg-9 mb-4">
                                    <div className="card card-sm h-100 shadow-none  card-transition">
                                        <div className="card-body ">
                                            <div className="table-responsive">
                                                <table className="table table-hover">
                                                    <thead>
                                                        <tr>
                                                            {
                                                                Object.keys(users[0]).map( (k,i) =>(
                                                                    <th scope="col" key={i}>{k.toLocaleUpperCase()}</th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            users.map( (user,i) =>(
                                                                <tr key={i}>
                                                                    {
                                                                        Object.keys(user).map((kk,i) =>(
                                                                            <td key={i}>{user[kk]}</td>
                                                                        ))
                                                                    }
                                                                </tr>
                                                                
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/*  */}
                            {
                                activeLink.id == 2 &&
                                <div className="col-lg-9 mb-4">
                                    <div className="card card-sm h-100 shadow-none  card-transition">
                                        <div className="card-body ">
                                            <h3>Add Users</h3>
                                            <div>
                                                <label>NAME</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div>
                                                <label>EMAIL</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div>
                                                <label>MOBILE</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div>
                                                <label>PASSWORD</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}
export default AdminConsole;