import { useParams } from "react-router-dom";
import {useEffect, useState} from 'react'
import { vars } from '../utils/constants';
import { apiGetCourseDetails } from "../apis/courses";
import Footer from "../Common/Footer";
import CourseSide from "./CourseSide";
import { generateRandomKey } from "../utils/crypto";
import { parseApiResponse } from "../utils/api";
import Offers from "../Common/Offers";
// import '../header2.css'
import SsPlayer from "../Paid/Learn/SsPlayer";
import { getUserIpData } from "../apis/security";

function CourseDetails({setShowAuthModal}){

    const { courseId } = useParams();

    const [course, setCourse] = useState([])
    const [topics, setTopics] = useState([])
    const [coupon, setCoupon] = useState("ABCD")
    const [syllabusCategory, setSyllabusCategory] = useState([])

    const [videoJsOptions, setVideoJsOptions] = useState({
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: []
    })
    const [videoAesKey, setVideoAesKey] = useState("")

    useEffect(() => {
        const getCourseDetails = async() =>{
            try{
                const aesKey = generateRandomKey()
                let response = await apiGetCourseDetails(courseId, aesKey);
                response = parseApiResponse({data: response.data, aesKey: aesKey})
                if(response.data.result == vars.SUCCESS){
                    setCourse(response.data.data.course)
                    const uniqueValues = new Set();
                    response.data.data.topics.forEach(item => {
                        uniqueValues.add(item.l_topic_desc);
                    });
                    const uniqueArray = Array.from(uniqueValues);
                    setSyllabusCategory([...uniqueArray])
                    setTopics(response.data.data.topics)
                    setCoupon({...response.data.data.coupon})
                    setVideoAesKey(response.data.data.aesKey)
                    if(response.data.data.course[0]['about_video_url'] != null){
                        setVideoJsOptions({...videoJsOptions, sources:[{src: `${vars.API_URLS.PROD}/videos/cf/${response.data.data.course[0]['about_video_url']}`, type: 'application/x-mpegurl'} ]})
                    }
                }else{
                    setCourse([])
                    setTopics([])
                }
                
            }catch(ex){
                setCourse([])
                setTopics([])
            }
        }
        getCourseDetails()
        
    },[courseId])

    const getBulletPoints = (data, isOdd, sep) =>{
        let result = []
        if( isOdd ){
            data.split(sep).map( (d, i) => {
                if( i % 2 != 0 ){
                    result.push(<li className="list-checked-item" key={i}>{d}</li>)
                }
            })
        }else{
            data.split(sep).map( (d, i) => {
                if( i % 2 == 0 ){
                    result.push(<li className="list-checked-item" key={i}>{d}</li>)
                }
            })
        }
        return  result
    }

    const [enableAuthModal, setEnableAuthModal] = useState(false)

    return(
        <div>
            <main id="content">
                <div className="bg-white">
                    {/* <Offers /> */}
                    <div className="position-relative">
                        <div className="gradient-y-overlay-lg-white bg-img-start content-space-t-3 content-space-t-md-4 content-space-t-lg-4">
                            <div className="container">
                                
                                <div className="row">
                                    <div className="col-md-7 col-lg-8">
                                        
                                        <h1 className="pt-1">
                                            {
                                                course.map( c => ( c.course_title ))
                                            }
                                        </h1>
                                        <p>
                                            {
                                                course.map( c => ( c.l_course_desc ))
                                            }
                                        </p>

                                        <div className="d-flex align-items-center flex-wrap">
                                        

                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {
                                        videoJsOptions.sources.length > 0 && 
                                        <div className="col-md-7 col-lg-8 mt-2">
                                            <h3 className="text-offer"></h3>
                                            <h3 className="h3 lh-sm">
                                                <span className="text-highlight-warning">Please watch this video </span> (Real-time work, syllabus, why we are better than other institutes)
                                            </h3>
                                        </div>
                                    }
                                    <div className="col-md-7 col-lg-8">
                                    {
                                        videoJsOptions.sources.length > 0 && <SsPlayer videoAesKey={videoAesKey} path={videoJsOptions.sources[0].src} showOtherComps={false} />
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            course.length > 0 && <CourseSide data={course[0]} courseId={course[0].l_course_id} coupon={coupon} setShowAuthModal={setShowAuthModal} />
                        }
                    </div>
                    <div className="container content-space-t-2 content-space-t-md-1 pb-6">
                        <div className="row">
                            <div className="col-md-7 col-lg-8">
                                <h3 className="mb-4 pt-2">What you'll learn</h3>
                                <div className="row">
                                    <div className="col-lg-6">
                                        {
                                            course.map( (c,i) => (
                                                <ul className="list-checked list-checked-primary" key={i}>{getBulletPoints(c.learn, false, "#")}</ul>
                                            ))
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        {
                                            course.map( (c,i) => (
                                                <ul className="list-checked list-checked-primary" key={i}>{getBulletPoints(c.learn, true, "#")}</ul>
                                            ))
                                        }
                                    </div>
                                </div>
                                <h3 className="mb-4 pt-5">Technologies & Concepts</h3>
                                <div className="row">
                                    <div className="col-lg-6">
                                        {
                                            course.map( (c,i) => (
                                                <ul className="list-checked list-checked-primary" key={i}>{getBulletPoints(c.technologies, false, ",")}</ul>
                                            ))
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        {
                                            course.map( (c,i) => (
                                                <ul className="list-checked list-checked-primary" key={i}>{getBulletPoints(c.technologies, true, ",")}</ul>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className=" pt-3 mt-3">
                                    <div className="row mb-4">
                                        <div className="col-8">
                                            <h3 className="mb-0">Course content</h3>
                                        </div>
                                    </div>
                                    {
                                        syllabusCategory.map((category,i) => (
                                            <div key={i}>
                                                <div className="row mb-4">
                                                    <div className="col-8">
                                                        <h3 className="mb-0"><span className="badge bg-warning-custom  shadow-lg rounded-pill h6-custom">{category}</span></h3>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered table-hover">
                                                        <tbody>
                                                            {
                                                                topics.map( (t,i) =>(
                                                                    t.l_topic_desc == category && 
                                                                    <tr key={i}>
                                                                        <td>
                                                                            <strong>{ i + 1}.&nbsp;</strong>
                                                                            {t.l_topic_name}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default CourseDetails