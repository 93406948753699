import { getOpenAxios } from '../utils/axios';
import { getLoggedInUserId, getUserJwtToken } from '../utils/utils';

export const apiGetUsers = async () => {
	return getOpenAxios().post('admin/getUsers',{token: getUserJwtToken(), userId: getLoggedInUserId() });
};




