import { useEffect, useRef, useState } from "react";
import { getLoggedInUserId, getLoginKey } from "../utils/utils";

function VideoOverlay(){

    const intervalRef= useRef(null);
    let ss6 = {zIndex: 2147483647,position:'absolute',top:0,left:0, color: '#000'}
    let ss7 = {zIndex: 2147483647,position:'absolute',top:0,right:0, color: '#000'}
    let ss8 = {zIndex: 2147483647,position:'absolute',bottom:0,left:0, color: '#000'}
    let ss9 = {zIndex: 2147483647,position:'absolute',bottom:0,right:0, color: '#000'}
    let classes = [ss6, ss7, ss8, ss9]
    let classId = 0
    const [cls, setCls] = useState(classes[classId])

    useEffect(() => {
        intervalRef.current = setInterval(async () => {
            if( classId == classes.length - 1){
                // console.log(0)
                classId = 0
                setCls(classes[0])
            }else{
                classId = classId +1
                setCls(classes[classId])
            }
            
        
        }, 2000);
        
        return () => {
            clearInterval(intervalRef.current);
        }
    }, []);

    return(
        <div style={cls}>
            UserId: {getLoggedInUserId()}
        </div>
    )
}

export default VideoOverlay