import { getJwtAxios } from '../utils/axios';
import { getLoggedInUserId, getUserJwtToken } from '../utils/utils';

export const apiGetLearningData = async (courseId, aesKey) => {
	return getJwtAxios().post('learn/getLearningTopics',{token: getUserJwtToken(), userId: getLoggedInUserId(), courseId:courseId, aesKey: aesKey  });
};

export const apiUpdateAssignmentStatus = async (courseId, courseTopicId, imgPath, aesKey) => {
	return getJwtAxios().post('learn/updateAssignmentStatus',{token: getUserJwtToken(), userId: getLoggedInUserId(), courseId:courseId, 
		courseTopicId: courseTopicId, imagePath: imgPath, aesKey: aesKey  });
};

export const apiUpdateTopicStatus = async (courseId, courseTopicId, aesKey) => {
	return getJwtAxios().post('learn/updateTopicStatus',{token: getUserJwtToken(), userId: getLoggedInUserId(), courseId:courseId, 
		courseTopicId: courseTopicId, aesKey: aesKey });
};

export const apiGetTopicAssignmentsList = async (courseId, courseTopicId, aesKey) => {
	// console.log(courseId, courseTopicId, aesKey)
	return getJwtAxios().post('learn/getTopicAssignmentsList',{token: getUserJwtToken(), userId: getLoggedInUserId(), courseId:courseId, 
		courseTopicId: courseTopicId, aesKey: aesKey });
};
