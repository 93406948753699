import { useState } from "react"
import { isEmailValid } from "../utils/utils";
import { apiLogin, apiResetPassword, apiSignup } from "../apis/auth";
import { vars } from "../utils/constants";
import { useCookies } from 'react-cookie';
import { generateRandomKey } from "../utils/crypto";
import { parseApiResponse } from '../utils/api';

function Auth({hideModal, togglerData}){

  
  const [toggler, setToggler] = useState({...togglerData})
  const [cookies, setCookie] = useCookies([vars.cookieNames.AWS_CLOUDFRONT_SECRET]);

  const updateToggler = (key) =>{

    let data = {...toggler}
    Object.keys(toggler).forEach(ele => {
      if(ele == key ){
        data[ele] = true
      }else{
        data[ele] = false
      }
    });
    setToggler({...data})

  }


  const [loginData, setLoginData] = useState({email:'', password:''})
  const [loginErrors, setLoginErrors] = useState({email:'', password:'', apiError: ''})
  const [isApiInProgress, setApiInProgress] = useState(false)

  const handleLogin = async() =>{
    try{
      let errorMessages = {...loginErrors}
      let errors = 0;
      if(isEmailValid(loginData.email)){
        errorMessages = {...errorMessages, email: ''}
      }else{
        errors +=1
        errorMessages = {...errorMessages, email: 'Email is not valid'}
      }

      if(loginData.password.length > 7){
        errorMessages = {...errorMessages, password: ''}
      }else{
        errors +=1
        errorMessages = {...errorMessages, password: 'Minimum 8 characters'}
      }

      setLoginErrors({...errorMessages})

      if( errors == 0 && isApiInProgress == false){
        
        setApiInProgress(true)
        const aesKey = generateRandomKey()

        let response = await apiLogin({...loginData})

        // response = parseApiResponse({data: response.data, aesKey: aesKey})
        if(response.data.result == vars.SUCCESS){
          setLoginErrors({...loginErrors, apiError: ''})
          setCookie(vars.cookieNames.AWS_CLOUDFRONT_SECRET, 'testsecret');
          localStorage.setItem("userData", JSON.stringify(response.data.data))
          window.location.reload();
        }else{
          setLoginErrors({...loginErrors, apiError: response.data.message})
        }
        setApiInProgress(false)

      }
    }catch(ex){
      setLoginErrors({...loginErrors, apiError: ex.message})
      setApiInProgress(false)
    }
  }

  const [signupData, setSignupData] = useState({name:'', email:'', mobile:'', password:''})
  const [signupErrors, setSingupErrors]  = useState({name:'', email:'', mobile:'', password:'', apiError:''})

  const handleSignup =async () =>{
    try{
      let errorMessages = {...signupErrors}
      let errors = 0;
      if(isEmailValid(signupData.email)){
        errorMessages = {...errorMessages, email: ''}
      }else{
        errors +=1
        errorMessages = {...errorMessages, email: 'Email is not valid'}
      }

      if(signupData.password.length > 7){
        errorMessages = {...errorMessages, password: ''}
      }else{
        errors +=1
        errorMessages = {...errorMessages, password: 'Minimum 8 characters'}
      }

      if(signupData.name.length > 2){
        errorMessages = {...errorMessages, name: ''}
      }else{
        errors +=1
        errorMessages = {...errorMessages, name: 'Minimum 3 characters'}
      }

      if(signupData.mobile.length > 9){
        errorMessages = {...errorMessages, mobile: ''}
      }else{
        errors +=1
        errorMessages = {...errorMessages, mobile: 'Minimum 10 characters'}
      }

      setSingupErrors({...errorMessages})

      if( errors == 0 && isApiInProgress == false){
        setApiInProgress(true)
        const aesKey = generateRandomKey()
        let response = await apiSignup({...signupData, aesKey: aesKey})
        response = parseApiResponse({data: response.data, aesKey: aesKey})
        if(response.data.result == vars.SUCCESS){
          setSingupErrors({...signupErrors, apiError: ''})
          setCookie(vars.cookieNames.AWS_CLOUDFRONT_SECRET, 'testsecret');
          localStorage.setItem("userData", JSON.stringify(response.data.data))
          window.location.reload();
        }else{
          setSingupErrors({...signupErrors, apiError: response.data.message})
        }
        setApiInProgress(false)
      }
    }catch(ex){
      setSingupErrors({...signupErrors, apiError: ex.message})
      setApiInProgress(false)
    }
  }

  const [resetData, setResetData] = useState({email: '', successMsg: ''})
  const [resetErrors, setResetErrors] = useState({email: '', apiError:''})

  const handleReset = async() =>{
    try{
      let errorMessages = {...resetErrors}
      let errors = 0;
      if(isEmailValid(resetData.email)){
        errorMessages = {...errorMessages, email: ''}
      }else{
        errors +=1
        errorMessages = {...errorMessages, email: 'Email is not valid'}
      }
      setResetErrors({...errorMessages})
      if( errors == 0 && isApiInProgress == false){
        setApiInProgress(true)
        const aesKey = generateRandomKey()
        let response = await apiResetPassword({...resetData, aesKey: aesKey})
        response = parseApiResponse({data: response.data, aesKey: aesKey})
        if(response.data.result == vars.SUCCESS){
          setResetErrors({...resetErrors, apiError: ''})
          setResetData({...resetData, successMsg: response.data.message})
        }else{
          setResetErrors({...resetErrors, apiError: response.data.message})
          setResetData({...resetData, successMsg: ''})
        }
        setApiInProgress(false)
      }

    }catch(ex){
      setResetErrors({...resetErrors, apiError: ex.message})
      setResetData({...resetData, successMsg: ''})
      setApiInProgress(false)
    }
  }

    return(
        <div className="modal fade show" id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display: 'block'}} >
          <div className="modal-dialog" role="document">
            <div className="modal-content">


              <div className="card">

                  {
                    toggler.login && 
                    <div id="login" data-target-group="idForm">
                  
                      <header className="card-header bg-light py-3 px-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="h6 mb-0">Welcome Back!</h3>

                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={e => hideModal(false) } >
                          </button>
                        </div>
                      </header>

                      <div className="card-body p-5">
                        <form id="loginForm">
                          {
                            loginErrors.apiError.length > 0 && <div className="alert alert-danger" role="alert">{loginErrors.apiError}</div>
                          }
                          
                          <div className="form-group">
                            <div className="js-form-message js-focus-state signinEmail-div mb-4">
                              <label className="sr-only form-label text-cap mb-1 text-secondary" htmlFor="signinSrEmail">Email</label>
                              <div className="input-group">

                                <input type="email" className="form-control"  placeholder="Email" onChange={e => setLoginData({...loginData, email: e.target.value})} />
                              </div>
                              <div className="invalid-feedback" style={{display: 'block'}}>{loginErrors.email}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="js-form-message js-focus-state signinPassword-div mb-4">
                              <label className="sr-only form-label text-cap mb-1 text-secondary" htmlFor="signinSrPassword">Password</label>
                              <div className="input-group">
                                <input type="password" className="form-control" placeholder="Password"  onChange={e => setLoginData({...loginData, password: e.target.value})}  />
                              </div>
                              <div className="invalid-feedback" style={{display: 'block'}}>{loginErrors.password}</div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-end mb-4">
                            <a className="pointer js-animation-link small link-muted" id="intForgotPword" data-target="#forgotPassword"
                             onClick={e => updateToggler('reset')}>Forgot Password?</a>
                          </div>

                          <div className="mb-2 d-grid">
                            <input type="hidden" name="source" value="website" />
                            <button type="button" className="btn btn-block btn-warning transition-3d-hover" onClick={e=>handleLogin()}>{ isApiInProgress ? 'Please wait...':'Login' }</button>
                          </div>

                          <div className="text-center mb-4">
                            <span className="small text-muted">Do not have an account?</span>
                            <a className="pointer js-animation-link small"  id="intSignupModal" onClick={e => updateToggler('signup')}>Signup
                            </a>
                          </div>
                        </form>
                      </div>
                      
                    </div>
                  }

                  {
                    toggler.signup && 
                    <div id="signup"  data-target-group="idForm">
                    
                      <header className="card-header bg-light py-3 px-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="h6 mb-0">Welcome to SoftwareSchool</h3>
                          <button type="button" className="btn-close"  onClick={e => hideModal(false) } >
                          </button>
                        </div>
                      </header>

                      <div className="card-body p-5">
                        <form id="signupForm">
                          {
                            signupErrors.apiError.length > 0 && <div className="alert alert-danger" role="alert">{signupErrors.apiError}</div>
                          }
                          <div className="form-group">
                            <div className="js-form-message js-focus-state signupName-div  mb-4">
                              <label className="sr-only form-label text-cap mb-1 text-secondary" htmlFor="signupSrEmail">Name</label> 
                              <div className="input-group">

                                <input type="name" className="form-control" onChange={e => setSignupData({...signupData, name: e.target.value})} placeholder="Name" required />
                              </div>
                              <div id="signupName-error" className="invalid-feedback" style={{display: 'block'}}>{signupErrors.name}</div>
                            </div>
                          </div>
                          

                          <div className="form-group">
                            <div className="js-form-message js-focus-state signupMob-div mb-4">
                              <label className="sr-only form-label text-cap mb-1 text-secondary" htmlFor="signupSrEmail">Mobile</label>
                              <div className="input-group ">

                                <div className="input-group align-items-center">
                                  
                                  <input type="text" className="form-control" placeholder="Mobile Number"  onChange={e => setSignupData({...signupData, mobile: e.target.value})}  />

                                </div>

                              </div>
                              <div id="signupMob-err" className="invalid-feedback">{signupErrors.mobile}</div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="js-form-message js-focus-state signupEmail-div mb-4">
                              <label className="sr-only form-label text-cap mb-1 text-secondary" htmlFor="signupSrEmail">Email</label>
                              <div className="input-group">

                                <input type="email" className="form-control"  onChange={e => setSignupData({...signupData, email: e.target.value})}  placeholder="Email"  required />
                              </div>
                              <div id="signupEmail-error" className="invalid-feedback" style={{display: 'block'}}>{signupErrors.email}</div>
                            </div>
                          </div>

                          <div className="form-group">
                            <div className="js-form-message js-focus-state  signupPassword-div mb-4">
                              <label className="sr-only form-label text-cap mb-1 text-secondary" htmlFor="signupSrPassword">Password</label>
                              <div className="input-group">

                                <input type="password" className="form-control"  onChange={e => setSignupData({...signupData, password: e.target.value})}  placeholder="Password"  required />
                              </div>
                              <div id="signupPassword-error" className="invalid-feedback" style={{display: 'block'}}>{signupErrors.password}</div>
                            </div>
                          </div>
                          <div className="mb-2 d-grid">
                            <button type="button" className="btn btn-block btn-warning transition-3d-hover" onClick={ e=> handleSignup()}>{ isApiInProgress ? 'Please wait...':'Sign up' }</button>
                          </div>

                          <div className="text-center mb-4">
                            <span className="small text-muted">Already have an account?</span>
                            <a className="pointer js-animation-link small" onClick={e => updateToggler('login')}>Login
                            </a>
                          </div>

                          

                          
                          <input type="hidden" name="source" value="website" />
                          
                        </form>
                      </div>
                    </div>
                  }

                  {
                    toggler.reset && 
                    <div id="forgotPassword"  data-target-group="idForm">
                      
                      <header className="card-header bg-light py-3 px-5">
                        <div className="d-flex justify-content-between align-items-center">
                          <h3 className="h6 mb-0">Recover Password.</h3>

                          <button type="button" className="btn-close"  onClick={e => hideModal(false) } >
                          </button>
                        </div>
                      </header>

                      <div className="card-body p-5">
                        <form id="sendRecoveryMailForm">
                          {
                            resetErrors.apiError.length > 0 && <div className="alert alert-danger" role="alert">{resetErrors.apiError}</div>
                          }
                          {
                            resetData.successMsg.length > 0 && <div className="alert alert-success" role="alert">{resetData.successMsg}</div>
                          }
                          <div className="form-group">
                            <div className="js-form-message js-focus-state recoveryEmail-div mb-4">
                              <label className="sr-only form-label text-cap mb-1 text-secondary" htmlFor="recoverSrEmail">Your email</label>
                              <div className="input-group">
                                <input type="email" className="form-control" onChange={e=> setResetData({...resetData, email: e.target.value})} placeholder="Your email"  required />
                              </div>
                              <div id="recoveryEmail-error" className="invalid-feedback" style={{display: 'block'}}></div>
                            </div>
                          </div>

                          <div className="mb-2 d-grid">
                            <input type="hidden" name="source" value="website" />
                            <button type="button" id="sendReoveryEmailBtn" className="btn btn-block btn-warning transition-3d-hover" onClick={e=> handleReset()}>{ isApiInProgress ? 'Please wait...':'Recover Password' }</button>
                          </div>

                          <div className="text-center mb-4">
                            <span className="small text-muted">Remember your password?</span>
                            <a className="pointer js-animation-link small" onClick={e => updateToggler('login')}>Login
                            </a>
                          </div>
                        </form>
                      </div>
                    </div>
                  }

              </div>
            </div>


          </div>
        </div>
    )

}

export default Auth
