
import { getJwtAxios } from '../../utils/axios';
import { getLoggedInUserId, getUserJwtToken } from '../../utils/utils';

export const apiUploadFile = (file, aesKey) => {
	const formData = new FormData();
	formData.append('userId', getLoggedInUserId());
	formData.append('image', file);
	formData.append('token', getUserJwtToken());
	formData.append('aesKey', aesKey)
	return getJwtAxios().post('/files/upload', formData);
};



