import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { apiGetLearningData, apiGetTopicAssignmentsList, apiUpdateAssignmentStatus, apiUpdateTopicStatus } from "../../apis/learn";
import { vars } from "../../utils/constants";
import Footer from "../../Common/Footer";
// import Player from "../../Player";
import { getFileExtension } from "../../utils/fileHelper";
import { apiUploadFile } from "../../apis/Util/file";
import SsPlayer from "./SsPlayer";
import { generateRandomKey } from "../../utils/crypto";
import { parseApiResponse } from '../../utils/api';
import TokenValidator from "../../Security/TokenValidator";
// import '../../header.css'
import Offers from '../../Common/Offers'

function Learn(){

    const { courseId } = useParams();
    const [courseTopics, setCourseTopics] = useState([])
    const [learnedTopics, setLearnedTopics] = useState([])
    const [activeTopicData, setActiveTopicData] = useState([])
    const [purchaseData, setPurchaseData] = useState([])
    const [showInFuture, setSowInFuture] = useState(false)
    const videoRef = useRef(null);
    const [assignmentData,setAssignmentData] = useState({file:'',apiStatus: false, success: '', error:''})
    const [videoAesKey, setVideoAesKey] = useState("")

    const [topicsCategory, setTopicsCategory] = useState("class")
    const [assignments, setAssignments] = useState([])
    const [noOfDays, setNoOfDays] = useState(0)

    useEffect(() =>{
        const getData = async() => {
            const aesKey = generateRandomKey()
            let response = await apiGetLearningData(courseId, aesKey)
            response = parseApiResponse({data: response.data, aesKey: aesKey})
            if(response.data.result == vars.SUCCESS){
                let data = [...response.data.data.courseTopics]
                data = data.map(d =>{
                    d.is_active_tab = false
                    return d
                })
                setCourseTopics([...data])
                setLearnedTopics([...response.data.data.learnedTopics])
                setPurchaseData([...response.data.data.purchaseData])
                setVideoAesKey(response.data.data.aesKey)
                // console.log(response.data.data.ordersData, getNoOfPurchaseDays([...response.data.data.ordersData]))
                setNoOfDays(getNoOfPurchaseDays([...response.data.data.ordersData]))
            }else{
                setCourseTopics([])
                setLearnedTopics([])
                setPurchaseData([])
            }
        }
        
        getData();
    },[])

    const getCourseListClasses = (ct) =>{
        return ct.is_active_tab ? "list-group-item pointer text-primary border-primary" : "list-group-item pointer"
    }

    const playerRef = useRef(null);

    const [videoJsOptions, setVideoJsOptions] = useState({
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: []
    })

    const getDayOfTheWeek = () =>{
        const daysOfWeek = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const currentDate = new Date();
        return daysOfWeek[currentDate.getDay()];
    }

    const getNoOfPurchaseDays = (tmpPurchaseData = null) =>{
        if(tmpPurchaseData == null){
            tmpPurchaseData = [...purchaseData]
        }
        let purchaseDate = new Date(tmpPurchaseData[0].created_on.substring(0, 10));
        let currentDate = new Date();
        var year = currentDate.getFullYear();
        var month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        var day = currentDate.getDate().toString().padStart(2, '0'); 
        var formattedDate = `${year}-${month}-${day}`;
        currentDate = new Date(formattedDate)
        // console.log(purchaseDate, currentDate)
        const timeDifference = currentDate - purchaseDate;
        return Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) + 1;
    }

    function countSundaysBetweenDates() {
        let sundayCount = 0;
        let currentDate = new Date();
        let endDate = new Date(purchaseData[0].created_on.substring(0, 10));
        while (endDate <= currentDate) {
          if (endDate.getDay() == 0) {
            sundayCount++;
          }
          endDate.setDate(endDate.getDate() + 1);
        }
        return sundayCount;
      }

    const handleCourseTopicClick = (ct, position) =>{
        setAssignmentData({file:'',apiStatus: false, success: '', error:''})
        let daysSincePurchase = getNoOfPurchaseDays()
        // console.log(daysSincePurchase)
        const dayOfTheWeek = getDayOfTheWeek()
        const blockedDays = ["SUN"]
        const noOfSundays = countSundaysBetweenDates()
        daysSincePurchase = daysSincePurchase - noOfSundays;
        
        
        // console.log(position, daysSincePurchase, noOfSundays)
        let data = [...courseTopics];
        data = data.map(d =>{
            if(d.course_topic_id == ct.course_topic_id){
                d.is_active_tab = true
            }else{
                d.is_active_tab = false
            }
            return d
        })
        // console.log(ct)
        setCourseTopics([...data])
        setActiveTopicData([{...ct}])
        setVideoJsOptions({...videoJsOptions, sources:[{src: `${vars.API_URLS.PROD}/videos/cf/${ct.url}`, type: 'application/x-mpegurl'} ]})
        getAssignments(ct.course_id, ct.course_topic_id)
        if(ct.category == vars.courseTopicTypes.CATEGORIES.LIVE){
            setSowInFuture(false)
            return;
        }
        
        if( position < daysSincePurchase ){
            setSowInFuture(false)
            return;
        }
        if( position == 0 && daysSincePurchase == 0 && blockedDays.includes(dayOfTheWeek) == true ){
            setSowInFuture(false)
            return;
        }
        // if( position == daysSincePurchase && blockedDays.includes(dayOfTheWeek) == false ){
        //     setSowInFuture(false)
        //     return;
        // }
        setSowInFuture(true)


    }

    const getAssignments = async(courseId, courseTopicId) =>{        
        const aesKey = generateRandomKey()
        let response = await apiGetTopicAssignmentsList(courseId, courseTopicId, aesKey)
        response = parseApiResponse({data: response.data, aesKey: aesKey})
        // console.log(response.data)
        if(response.data.result == vars.SUCCESS){
            setAssignments(response.data.data.assignments)
        }else{
            setAssignments([])
        }
    }

    const isTopicCompleted = (at) =>{
        let item = learnedTopics.filter(lt => lt.course_topic_id == at.course_topic_id && lt.status == 'completed')
        return item.length > 0 ? true:false
    }

    


    

    const hanldeAssignmentFile = async(e) =>{
        try{
            let tempFileData = {...assignmentData}
            if( e.target.files.length >0 ){
                let file = e.target.files[0]
                if(vars.FILES_UPLOAD.ASSIGNMENT_INCLUDES.includes(getFileExtension(file.name)) == false){
                    setAssignmentData({...tempFileData, error: "Only zip files allowed"})
                    return;
                }
                if(vars.FILES_UPLOAD.MAX_SIZE < file.size){
                    setAssignmentData({...tempFileData, error: vars.FILES_UPLOAD.ERRORS.SIZE_EXCEDED})
                    return;
                }
                setAssignmentData({...tempFileData, file: file, error:''})
            }
        }catch(ex){
            setAssignmentData({...assignmentData, error: ex.message})
        }
    }

    const [assignmentApi, setAssignmentApi] = useState(false)

    const handleSubmitAssignment = async () =>{
        try{
            if(assignmentData.file != ''){
                setAssignmentApi(true)
                let aesKey = generateRandomKey()
                let response = await apiUploadFile(assignmentData.file, aesKey)
                response = parseApiResponse({data: response.data, aesKey: aesKey})
                if(response.data.result == vars.SUCCESS){
                    let imagePath = response.data.data.imagePath;
                    aesKey = generateRandomKey()
                    response = await apiUpdateAssignmentStatus(courseId, activeTopicData[0].course_topic_id, imagePath, aesKey)
                    response = parseApiResponse({data: response.data, aesKey: aesKey})
                    if(response.data.result == vars.SUCCESS){
                        setAssignmentData({...assignmentData, success:response.data.message, error:''})
                    }else{
                        setAssignmentData({...assignmentData, error:response.data.message, success:''})
                    }
                }else{
                    setAssignmentData({...assignmentData, error:response.data.message, success:''})
                }
                setAssignmentApi(false)
            }else{
                setAssignmentData({...assignmentData, error:'Please select file', success: ''})
            }
        }catch(ex){
            setAssignmentApi(false)
            setAssignmentData({...assignmentData, error:ex.message, success: ''})
        }
    }

    const [taskCompleteApi, setTaskCompletedApi] = useState({apiStatus: false, success: '', error:''})

    const handleMarkTaskCompleted = async () =>{
        try{
            if(taskCompleteApi.apiStatus == false){
                setTaskCompletedApi({...taskCompleteApi, apiStatus: true})
                let aesKey = generateRandomKey()
                let response = await apiUpdateTopicStatus(courseId, activeTopicData[0].course_topic_id, aesKey)
                response = parseApiResponse({data: response.data, aesKey: aesKey})
                if(response.data.result == vars.SUCCESS){
                    setLearnedTopics([...response.data.data.learnedTopics])
                    setTaskCompletedApi({...taskCompleteApi, success:response.data.message, error:'', apiStatus: false})
                }else{
                    setTaskCompletedApi({...taskCompleteApi, error:response.data.message, success:'', apiStatus: false})
                }
            }
        }catch(ex){
            setTaskCompletedApi({...taskCompleteApi, error:ex.message, success: '', apiStatus: false})
        }
    }

    
   

    return(
        <div >
            <main id="content">
                {/* <Offers /> */}
                <div className="bg-light pb-6">
                    <div className="container position-relative content-space-t-3 content-space-t-md-4 content-space-t-lg-4 pb-6">
                        <div className="row ">
                            <div className="col-sm-6 col-lg-3 mb-4">
                                <div className="card card-sm h-100 shadow-none  card-transition ss-scroll">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button type="button" className={ topicsCategory == "class" ? 'btn btn-primary':'btn btn-white' } onClick={e=> setTopicsCategory("class")}>Classes</button>
                                        <button type="button" className={ topicsCategory == "live" ? 'btn btn-primary':'btn btn-white' } onClick={e=> setTopicsCategory("live")}>Live</button>
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        {
                                            courseTopics.map( (ct, i) => (
                                                ct.category == topicsCategory && 
                                                <li className={getCourseListClasses(ct)} onClick={e=>handleCourseTopicClick(ct, i)}  key={ct.course_topic_id}>
                                                    <div>{ct.title}</div>
                                                    {
                                                        ct.is_active_tab && 
                                                        <div className="mt-2">
                                                            <ul className="list-pointer mb-0">
                                                                {
                                                                    ct.title_points.length > 0 && ct.title_points.split('#').map( (item, i) => (
                                                                        <li className="list-pointer-item fs-p9 fw-400" key={i}>{item}</li>
                                                                    ))
                                                                }
                                                            </ul>
                                                        </div>
                                                    }
                                                    
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-9 mb-4">
                                {
                                    activeTopicData.length == 0 && 
                                    <div className="card card-sm h-100 shadow-none  card-transition">
                                        <div className="card-body ">
                                            <div>
                                                <h3>Please select topic from the left side list</h3>
                                            </div>
                                            <div>
                                                <strong className="text-danger">Note</strong>
                                                <ul>
                                                    <li>Screen recording and downloading these videos is illeagal</li>
                                                    <li>Sharing credetntials with friends/colleagues or others is prohibited, if found, account will be suspended</li>
                                                    {
                                                        noOfDays >= 8 && noOfDays > 0 &&
                                                        <li>Your refund window (7 days) closed. We will not be able to process your refund. We hope our content is helping you.</li>
                                                    }
                                                    {
                                                        noOfDays < 8 &&  noOfDays > 0 &&
                                                        <li className="text-danger">You are under refund window (1 week refund policy). If you don't like our content/syllabus, you can ask for refund.  
                                                        <a className="text-dark strong" target="_blank" href="https://web.whatsapp.com/send?phone=919032029072&text=Process+my+refund&app_absent=0">Click here for refund</a>. No refunds will be processed after this refund window. </li>
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    showInFuture && 
                                    <div className="card card-sm h-100 shadow-none  card-transition">
                                        <div className="card-body ">
                                            <div className="text-center h3">Lecture will be activated soon.</div>
                                            {/* <p>
                                                <strong>Monday to Saturday: </strong> One new video per day
                                            </p>
                                            <p>
                                                <strong>Saturday: </strong> Live session
                                            </p> */}
                                        </div>
                                    </div>
                                }
                                {
                                    showInFuture == false && activeTopicData.map(at => (
                                        
                                        at.category == topicsCategory && 
                                        <div className="card card-sm h-100 shadow-none  card-transition" key={at.course_topic_id}>
                                            <div className="card-body">
                                                <h3 className="card-title mb-3">{at.title}</h3>
                                                <p>{at.desc}</p>
                                                {
                                                    at.type == vars.courseTopicTypes.VIDEO && videoJsOptions.sources[0].src.length > 0 && <SsPlayer videoAesKey={videoAesKey} path={videoJsOptions.sources[0].src}  showOtherComps={true} />
                                                }
                                            </div>
                                            {
                                                at.data != null && at.category == vars.courseTopicTypes.CATEGORIES.CLASS && 
                                                <div className="card-body border-top">
                                                    <h3 className="card-title mb-3">{at.data.title}</h3>
                                                    <p>Task: {at.data.desc}</p>
                                                    <div className="p">
                                                        Complete the task and upload your code here.
                                                        <div>
                                                            <div className="row mt-3 align-items-center">
                                                                <div className="col-4"><input type="file" onChange={e=>hanldeAssignmentFile(e)} /> </div>
                                                                <div className="col-8"><button className="btn btn-primary" onClick={e=>handleSubmitAssignment()}>{assignmentApi ? 'Please wait...':'Submit Assignment'}</button></div>
                                                            </div>
                                                            {
                                                                assignmentData.error.length > 0 && <div className="alert alert-danger">{assignmentData.error}</div>
                                                            }
                                                            {
                                                                assignmentData.success.length > 0 && <div className="alert alert-success">{assignmentData.success}</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {
                                                <div className="card-body border-top">
                                                    {
                                                        assignments.length > 0 && <h3>Assignments</h3>
                                                    }
                                                    {
                                                        assignments.map( (item, i) => (
                                                            <div className="border-bottom mb-3 pb-3" key={i}>
                                                                <div className="pb-1">
                                                                    File: <a href={"https://d2b98ifobtd07j.cloudfront.net/user_uploads/" + item.url} target="_blank">Download File</a>
                                                                </div>
                                                                <div>
                                                                    Review Comments: {item.reviews}
                                                                </div>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            }
                                            {
                                                isTopicCompleted(at) == false && at.category == vars.courseTopicTypes.CATEGORIES.CLASS &&
                                                <div className="card-footer border-top">
                                                    <button className="btn btn-success" onClick={e=>handleMarkTaskCompleted(at)}>{taskCompleteApi.apiStatus ? 'Please wait...':'Mark as completed'}</button>
                                                </div>
                                            }
                                        </div>
                                        
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <TokenValidator />
                <Footer />
            </main>
        </div>
    )
}
export default Learn;