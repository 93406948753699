import { v4 as uuidv4 } from 'uuid';
import { vars } from './constants';

export const isEmailValid = (email) => {
	//let regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	return emailRegex.test(email);
};

export const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

export const getLoggedInUserId = () => {
	let data = JSON.parse(localStorage.getItem('userData'))
	return data.userId;
}
export const getUserJwtToken = () => {
	let data = JSON.parse(localStorage.getItem('userData'))
	return data.token;
}
export const getSessionId = () => {
	return localStorage.getItem('session_id')
}
export const getLoginKey = () => {
	let data = JSON.parse(localStorage.getItem('userData'))
	return data.key;
}
export const getLoggedInUserName = () =>{ 
	let data = JSON.parse(localStorage.getItem('userData'))
	return data.name
};
export const getLoggedInUserEmail = () => { 
	let data = JSON.parse(localStorage.getItem('userData'))
	return data.email
};
export const getUserCountry = () => { 
	let data = JSON.parse(localStorage.getItem('ipData'))
	if(data == undefined){
		return "IN"
	}
	if(data.hasOwnProperty("country")){
		return data.country
	}
	return "IN"
};
export const isUserLoggedIn = () =>  { 
	return localStorage.getItem('userData') == undefined ? false : true; 
}

export const isAdmin = () =>{
	if (localStorage.getItem('userData') == undefined ){
		return false
	}
	let data = JSON.parse(localStorage.getItem('userData')).userId
	return vars.adminsList.includes(data)
}

export const isUrlValid = (url) => {
	return urlRegex.test(url);
};

export const urlRegex = /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/;


export const getRandomUUID = () =>{
	return uuidv4();
}

export const trimAndAddDots = (data, maxLength) => {
	return data.length < maxLength ? data : data.substring(0,25) + "..." 
}

export const textAndLoadingText = (data, showLoaing) =>{
	if(showLoaing){
		return <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>;
	}else{
		return data;
	}
}

