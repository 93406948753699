function Job({data}){


    return(
        <div className="col-lg-12">
            <div className="card shadow mt-3">
                <div className="card-body">
                    <h3 className="card-title">{data.title}</h3>
                    <p>{data.desc}</p>
                    <span className="me-4"><span className="text-dark">Technologies:</span> {data.technologies}</span> 
                    <span className="me-4"><span className="text-dark">Salary:</span> {data.salary == null ? "Not Disclosed" : data.salary}</span>
                    <span className="me-4"><span className="text-dark">Experience:</span> {data.experience}</span>
                    <spanp className="me-4"><span className="text-dark">Location:</span> {data.location}</spanp>
                    <span><span className="text-dark">Source:</span> {data.source}</span>
                    <div className="mt-3">
                        <a className="btn btn-soft-warning" href={data.job_link} target="_blank">Visit {data.source}</a>
                    </div>
                </div>
            </div>
        </div>
    )

}
export default Job