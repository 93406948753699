import Footer from "../Common/Footer"

function Terms(){

    return(

        <div >
            <main id="content">
                <div className="bg-light">
                    <div className="container ">
                        <div className="row justify-content-lg-between align-items-lg-center pt-5 ">
                            <div className="col-lg-12 mt-5 pt-5 text-center">
                                <h2 className="mt-5">Terms and Conditions</h2>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>1. Course Enrollment:</h3>
                                <ul>
                                    <li>By enrolling in any coding course offered on this platform, you agree to abide by these terms and conditions.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>2. Course Access:</h3>
                                <ul>
                                    <li>Upon successful enrollment and payment, you will be granted access to the course materials for the duration specified in the course description.</li>
                                    <li>Access is granted solely to the individual who purchased the course. Sharing login credentials or course materials with others is strictly prohibited.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>3. Payment:</h3>
                                <ul>
                                    <li>Payment for courses must be made in full at the time of enrollment.</li>
                                    <li>All prices are listed in INR and are inclusive of any applicable taxes.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>4. Refund Policy:</h3>
                                <ul>
                                    <li>Refunds will be provided only within 7 days of the initial purchase date, subject to the discretion of SoftwareSchool.</li>
                                    <li>Refunds will not be issued if you have accessed more than 15% of the course content.</li>
                                    <li>To request a refund, please contact contact@softwareschool.co with your purchase details.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>5. Course Content:</h3>
                                <ul>
                                    <li>All course materials, including but not limited to videos, lectures, assignments, and quizzes, are the intellectual property of SoftwareSchool.</li>
                                    <li>You may not reproduce, distribute, or modify course materials without explicit permission from SoftwareSchool.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>6. Student Conduct:</h3>
                                <ul>
                                    <li>You agree to engage in respectful and constructive behavior within course discussions, forums, and any other interactive platforms provided.</li>
                                    <li>SoftwareSchool reserves the right to remove any student from the course without refund if their conduct is deemed inappropriate, abusive, or disruptive.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>7. Technical Requirements:</h3>
                                <ul>
                                    <li>It is your responsibility to ensure that you have access to the necessary technology and internet connection to participate in the course.</li>
                                    <li>SoftwareSchool is not responsible for any technical issues on your end that may affect your ability to access course materials.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>8. Limitation of Liability:</h3>
                                <ul>
                                    <li>SoftwareSchool shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of the use or inability to use the course materials.</li>
                                    <li>SoftwareSchool's liability shall be limited to the amount paid for the course.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>9. Modifications:</h3>
                                <ul>
                                    <li>SoftwareSchool reserves the right to modify these terms and conditions at any time without prior notice. Any changes will be effective immediately upon posting on the website.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <h3>10. Governing Law:</h3>
                                <ul>
                                    <li>These terms and conditions shall be governed by and construed in accordance with the laws of Telangana government.</li>
                                    <li>Any disputes arising under these terms and conditions shall be subject to the exclusive jurisdiction of the courts of Telangana government.</li>
                                </ul>
                            </div>
                            <div className="col-lg-12 mt-5 pt-5">
                                <p>By enrolling in a coding course provided by SoftwareSchool, you acknowledge that you have read, understood, and agreed to these terms and conditions.</p>
                                <p>For any inquiries or clarifications regarding these terms and conditions, please contact contact@softwareschool.co.</p>
                            </div>

                        </div>
                    </div>
                </div>
                <Footer />
            </main>
        </div>
    )
}

export default Terms
