
import Footer from '../Common/Footer';
import Navbar from '../Common/Navbar';
import {useEffect, useState} from 'react'
import { apiGetCourses } from '../apis/courses';
import { vars } from '../utils/constants';
import Course from './Course';
import { generateRandomKey } from '../utils/crypto';
import { parseApiResponse } from '../utils/api';
import Offers from '../Common/Offers';
// import '../header2.css'

function Courses() {

    const [coursesList, setCourses] = useState([])

    useEffect(() => {
        const getCourses = async() =>{
            try{
                const aesKey = generateRandomKey()
                let response = await apiGetCourses(aesKey);
                response = parseApiResponse({data: response.data, aesKey: aesKey})
                if(response.data.result == vars.SUCCESS){
                    setCourses(response.data.data.courses)
                }else{
                    setCourses([])
                }
            }catch(ex){
                setCourses([])
            }
        }
        getCourses()

    },[])

    return (
    <div >
        
        <main id="content">
            {/* <Offers /> */}
            <div className="bg-light pb-6">
                <div className="container position-relative content-space-t-3 content-space-t-md-4 content-space-t-lg-4 pb-6">
                    
                    <div className="row justify-content-lg-between align-items-lg-center">
                        <div className="col-lg-12 text-center mb-4">
                            <div className="mb-7 ">
                                <h2 className="display-4 mb-3 h1">In-demand courses for quick job</h2>
                            </div>
                        </div>
                    </div>
                    
                    <div className="row mb-5 mb-md-0 pb-3">
                        {
                            coursesList.map( c => (
                                <Course key={c.l_course_id} data={c} />
                            ))
                        }
                    </div>
                    
                </div>
            </div>
        <Footer />
        </main>
    </div>
    );
    }

export default Courses;
