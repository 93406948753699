import {useState} from 'react'
import { isEmailValid } from '../utils/utils'
import { apiCallRequest } from '../apis/auth'
import { vars } from '../utils/constants'
import { generateRandomKey } from "../utils/crypto";
import { parseApiResponse } from '../utils/api';
import Offers from '../Common/Offers';

function BookDemo(){

    const [demoData, setDemoData] = useState({name:'', email:'', mobile:'', apiMessage:''})
    const [demoErrors, setDemoErrors] = useState({name:'', email:'', mobile:'', apiError:''})
    const [isApiInProgress, setApiInProgress] = useState(false)

    const handleCallRequest =async () =>{
        try{
          let errorMessages = {...demoErrors}
          let errors = 0;
          if(isEmailValid(demoData.email)){
            errorMessages = {...errorMessages, email: ''}
          }else{
            errors +=1
            errorMessages = {...errorMessages, email: 'Email is not valid'}
          }
    
          if(demoData.name.length > 2){
            errorMessages = {...errorMessages, name: ''}
          }else{
            errors +=1
            errorMessages = {...errorMessages, name: 'Minimum 3 characters'}
          }
    
          if(demoData.mobile.length > 9){
            errorMessages = {...errorMessages, mobile: ''}
          }else{
            errors +=1
            errorMessages = {...errorMessages, mobile: 'Minimum 10 characters'}
          }
    
          setDemoErrors({...errorMessages})
    
          if( errors == 0 && isApiInProgress == false){
            setApiInProgress(true)
            const aesKey = generateRandomKey()
            let response = await apiCallRequest({...demoData, aesKey: aesKey})
            response = parseApiResponse({data: response.data, aesKey: aesKey})
            if(response.data.result == vars.SUCCESS){
              setDemoErrors({...demoErrors, apiError: ''})
              setDemoData({...demoData, apiMessage: response.data.message})
            }else{
                setDemoErrors({...demoErrors, apiError: response.data.message})
            }
            setApiInProgress(false)
          }
        }catch(ex){
            setDemoErrors({...demoErrors, apiError: ex.message})
          setApiInProgress(false)
        }
      }
    
    return(
        <div className="bg-light pb-6">
            
            <div className="container position-relative content-space-t-3 content-space-t-md-4 content-space-t-lg-3 pb-6">
                
                <div className="row justify-content-lg-between align-items-lg-center">
                    <div className="col-md-10 col-lg-6">
                        <div className="mb-7 ">
                            <h2 className="display-4 mb-3 h1">Learn Real-Time Work & Crack interviews Easily</h2>
                            <p className="lead">Learn in-demand career paths from industry-experts and become job-ready</p>
                        </div>
                    </div>

                    <div className="col-lg-5">
                        <form className="js-validate needs-validation" id="demoForm">
                          
                          
                            <div className="card shadow-none">


                                <div className="card-body">
                                
                                    <div>
                                        <h2 className="h2 text-center lh-sm mb-4">Connect with us</h2>
                                    </div>
                                    {/* <div className="row gx-3">
                                        <div className="mb-4">
                                            <label className="form-label text-cap mb-1 text-secondary" htmlFor="demoName">YOUR NAME</label>
                                            <input type="text" className="form-control form-control-lg" onChange={e=>setDemoData({...demoData, name: e.target.value})} placeholder="Name"  />
                                            <span className="invalid-feedback demoName">{demoErrors.name}</span>
                                        </div>

                                    </div>
                                    <div className="mb-4">
                                        <label className="form-label text-cap mb-1 text-secondary" htmlFor="demoEmail">EMAIL</label>
                                        <input type="email" className="form-control form-control-lg" placeholder="Email" onChange={e=>setDemoData({...demoData, email: e.target.value})}  />
                                        <span className="invalid-feedback demoEmail">{demoErrors.email}</span>
                                    </div>

                                    
                                    <div className="mb-4" data-hs-validation-validate-class>
                                        <label className="form-label text-cap mb-1 text-secondary" htmlFor="demoMobile">MOBILE</label>
                                        <input type="text" className="form-control form-control-lg" placeholder="Mobile number"  onChange={e=>setDemoData({...demoData, mobile: e.target.value})} />
                                        <span className="invalid-feedback demoMobile">{demoErrors.mobile}</span>
                                    </div>

                                    <div className="d-grid mb-3">
                                        <input type="hidden" id="demoFormId" name="demoFormId" value="homePageTop" />
                                        <button type="button" className="btn btn-soft-warning btn-lg submitDemoClass" onClick={e=>handleCallRequest()} >{ isApiInProgress ? 'Please wait...':'Request Callback' }</button>
                                    </div> */}
                                    
                                    <div className="mt-2 resultBlock">
                                        {
                                            demoErrors.apiError.length > 0 && <div className="alert alert-danger" role="alert">{demoErrors.apiError}</div>
                                        }
                                        {
                                            demoData.apiMessage.length > 0 && <div className="alert alert-success" role="alert">{demoData.apiMessage}</div>
                                        }
                                    </div>

                                    <div className="d-grid mb-3">
                                        <a type="button" className="btn btn-warning btn-lg" target='_blank' href="https://contact-softwareschool.zohobookings.in/#/225217000000033008"  >Book a Meeting</a>
                                    </div>
                                    <div className='text-secondary text-center m-3'>
                                      OR
                                    </div>
                                    <div className="d-grid mb-3">
                                        <a type="button" className="btn btn-primary btn-lg" target='_blank' href="https://zfrmz.in/NroR5MGc07tKleIhJve0"  >Request Callback</a>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BookDemo;
