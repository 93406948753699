import { getOpenAxios } from '../utils/axios';
import { getLoggedInUserId } from '../utils/utils';

export const apiLogin = async (data) => {
	return getOpenAxios().post('/auth/login',data);
};

export const apiSignup = async (data) => {
	return getOpenAxios().post('/auth/signup',data);
};

export const apiResetPassword = async (data) => {
	return getOpenAxios().post('/auth/reset',data);
};

export const apiCallRequest = async (data) => {
	return getOpenAxios().post('/auth/call-request',data);
};



