import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import {getCurrentUTCtime} from '../../utils/date'
import {encryptAESData} from '../../utils/crypto'
import VideoOverlay from "../../Security/VideoOverlay";
import TogleFullscreen from "../../Security/TogleFullscreen";
import { getLoggedInUserId, getSessionId, getUserCountry } from "../../utils/utils";

function SsPlayer({path, videoAesKey, showOtherComps}) {
  const videoRef = useRef(null);

  let isFullScreen = false
  

  const getUrlPart = (data) =>{
    let part  = data.split('/')
    if(part.length == 4){
      return data
    }else{
      let d = []
      part.map((p,i) =>{
        if(i != 0 && i !=1 ){
          d.push(p)
        }
      })
      // console.log(data, d)
      return d.join("/")
    }
  }

  useEffect(() => {
    // try{

    
    var config = {
      xhrSetup: function (xhr, url) {
        let timeStamp = getCurrentUTCtime()
        let userId = 0;
        if(localStorage.getItem('userData') != undefined){
          userId = getLoggedInUserId();
        }
        const uniqueText = timeStamp + '_' + Math.floor(Math.random() * 10000) + '_' + getSessionId() + '_' + userId;
        // console.log(uniqueText)
        let encData = encryptAESData(uniqueText.toString(), videoAesKey)
        let newUrl = url.split("/cf/")
        
        xhr.open('GET', newUrl[0] + '/cf/' + getUserCountry() +'/'  + encodeURIComponent(encData) + '/' + getUrlPart(newUrl[1]), true);
      }
    };
    const video = videoRef.current;
    const handleFullScreenChange = () => {
      isFullScreen = document.fullscreenElement !== null
    };
    // console.log('Hls.isSupported()', Hls.isSupported())
    const hls = new Hls(config);
    // const url = "https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8";

    hls.loadSource(path);
    hls.attachMedia(video);
    // hls.startLevel = 62
    hls.on(Hls.Events.MANIFEST_PARSED, function() {
    });
    hls.on(Hls.Events.ERROR, function(event, data){
      // console.log('HLS error: ', data)
      if (data.type === Hls.ErrorTypes.NETWORK_ERROR) {
        if (data.details === Hls.ErrorDetails.MANIFEST_LOAD_ERROR ||
            data.details === Hls.ErrorDetails.LEVEL_LOAD_ERROR ||
            data.details === Hls.ErrorDetails.FRAG_LOAD_ERROR) {
          if (data.response && data.response.code === 401) {
            let ipData = localStorage.getItem("ipData")
            let sessioData = localStorage.getItem("session_id")
            localStorage.clear()
            if(ipData != undefined){
              localStorage.setItem('ipData',ipData)
            }
            if(sessioData != undefined){
              localStorage.setItem('session_id',sessioData)
            }
            window.location = '/'
          }
        }
      }
    })

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  // }catch(ex){
  //   console.log('exception', ex)
  // }
  }, []);

  // const updateUrl = async(event, data) =>{
  //   http://localhost:8080/videos/cf/reactjs_2023/html/1/html1_.m3u8
  // }

  const handleFullScreenToggle = () => {
    if (isFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  return (
    <div className="ss-pos-rel">
      <video
          className="videoCanvas video-player-ss ss-pos-rel ss-zid-5"
          ref={videoRef}
          autoPlay={false}
          controls={true}
      />
      { showOtherComps == true && <VideoOverlay /> }
      { showOtherComps == true && <TogleFullscreen handleChange={handleFullScreenToggle} /> }
    </div>
    
  );
}

export default SsPlayer;
