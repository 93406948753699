
import { getJwtAxios, getOpenAxios } from '../utils/axios';
import { getLoggedInUserId, getLoginKey, getUserJwtToken } from '../utils/utils';

export const apigetSignedCookies = async () => {
	return getOpenAxios().post('security/getSignedCookies',{});
};

export const apiGetVideos = async () => {
	return getOpenAxios().get('security/getVideos',{});
};

export const apiValidateLoginKey = async ( aesKey) => {
	return getJwtAxios().post('auth/auth-validator',{token: getUserJwtToken(), userId: getLoggedInUserId(), login_key:getLoginKey(), aesKey: aesKey  });
};

export const getUserIpData = async () =>{
	return getOpenAxios().get("auth/ip-data",{})
}